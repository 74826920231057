

const validationInput = (input :string, type:string) => {

  switch (type) {
    case 'email':
      const emailRegex = /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      //email not valid
      if (!input.match(emailRegex)) {
        return {success: false, message: 'Email not valid, please enter a valid email address', class:'is-invalid'}
      } else {
        return {success: true, message:'Email correct', class: 'valid'}
      }
    case 'minchars' :
      var inputLen = input.length;
      //console.log('inputLen', inputLen);
      if (inputLen <= 3) {
        return { success: false, message: 'field must be at least four characters long', class:'is-invalid'}
      } else {
        return { success: true, message:'Name correct', class: 'valid'}
      }

    
      
  }


}

export default validationInput;