import { any } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import './App.css';
import srvConn from './helpers/srvConn';
import validationInput from './helpers/validationInput';

function App() {

  const nameRef = useRef(null);
  const emailFormRef = useRef(null);
  const commentRef = useRef(null);
  const countRef = useRef(null);
  const divResponse = useRef<any>(null);

  const emailRef = useRef(null);
  const [emailVal, setEmailVal] = useState('');
  const [response, setResponse] = useState('');
  const [isValid, setisValid] = useState('');
  const [msgResponse, setmsgResponse] = useState('');

  const [forms, setForms] = useState(Array());
  const [invalidFields,setInvalidFields] = useState('');
  
  const submitForm = async (e:any) => {
    e.preventDefault();

    console.log('forms', forms);
    var msg= '';
    console.log('msg', msg);
    forms.map(async (input, i) => {
      
      switch (input.current.name) {
        case 'email' :
          var tmp = validationInput( input.current.value,'email' );
          input.current.className = tmp?.class;
          break;
        default :
          var tmp = validationInput( input.current.value,'minchars' );
          
          

      }

      input.current.className = 'form-control' + ' '+ tmp?.class;
      
      var message = (!tmp?.success) ? tmp?.message : undefined;
      if (message !== undefined) {
        input.current.message = message;
        

       if (i == 0)
            { 
              
              msg = `<ul><li>${input.current.name} - ${input.current.message}</li>`;
            } else {
              
              msg = msg + `<li> ${input.current.name} - ${input.current.message}</li>`;
            }
      
      }
     //console.log(i, forms.length - 1);
      if (i == (forms.length - 1)) {
       
       if (msg !== '') {
        divResponse.current.innerHTML = `<p>Please, correct below field(s):</p>`+ msg +'</ul>';
       } else {
        //all input fields are correct so will send the email
        

        console.log('prepare response');
        var srvRoute = '/se/contact-form';
        var  postData : {[index: string]:any} = {}
        
        forms.forEach((field, i) => { postData[field.current.name] = field.current.value});
        console.log('postData',postData)

        let res = await srvConn(srvRoute, postData);

        

        if (res?.success) {
          divResponse.current.innerHTML = `Form correctly sent`;

          var el = document.getElementsByClassName("contact-form-validated");
          var el_comment = document.getElementsByClassName("comment-form");
          while(el.length > 0){
            el[0].parentNode?.removeChild(el[0]);
            
        }


        console.log('lenght',  el_comment.length);

        try {
        const para = document.createElement("p");
        const node = document.createTextNode("Data correctly sent");

        // Append text node to the p element:
        para.appendChild(node);
        el_comment[0].appendChild(para);

        } catch (err) {
          console.log(err);
        }


        var el_contact = document.getElementsByClassName("contact-form") as HTMLCollectionOf<HTMLElement>;
       

        if (el_contact !== null) {
        for (i = 0; i < el_contact.length; i++) {
          el_contact[i].parentNode?.removeChild(el_contact[i]);
         
        }
      }
          
         } else {

          var el_contact = document.getElementsByClassName("contact-form") as HTMLCollectionOf<HTMLElement>;
       

          if (el_contact !== null) {
          for (i = 0; i < el_contact.length; i++) {
            el_contact[i].parentNode?.removeChild(el_contact[i]);
           
          }
        }

          divResponse.current.innerHTML = `There was a problem sending the form, please retry later`;

        
        }


       }
        

    }
      
    })

  }

  const handleClick = async (e:any) => {
    e.preventDefault();
    setTimeout(function() {
      //remove is-invalid class
      setisValid('');
    }, 1000); 

    var resEval = validationInput(emailVal, 'email');
    console.log('resEval', resEval);

    if (!resEval?.success) {
    
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (resEval?.class) ? setisValid(resEval?.class) : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (resEval?.message) ? setResponse(resEval?.message) : null;
    
    } else {
      //valid email
      console.log('email valid')
      var srvRoute = '/se/welcome';
      var email = emailVal;

      console.log('email', email);

      var postData = {
        email: email,
        active: true
      };
      console.log('postdata', postData);
     
        var res = await srvConn(srvRoute,postData);
      
        console.log('res', res);
       if (res?.success) {
        console.log("success",res);
        setResponse("Email correctly sent.");
        var el = document.getElementById("email");
        el?.remove();
        setmsgResponse(`Thanks for your subscription at the email ${emailVal}`);
        var elBtn = document.getElementById("btn-reset");
        elBtn?.remove();

        } else {
          console.log("error",res);
          var value_error = (res.error?.includes("E11000 duplicate key")) ? "Email already subscribed" : "There was a problem sending the email";
          setResponse(value_error);
          var el = document.getElementById("email");
          el?.remove();
          var elBtn = document.getElementById("btn-reset");
         elBtn?.remove();
        }

     

    }

    
  }

  useEffect(() => {

    console.log('----> invaliidFields',invalidFields);
    if (forms.length == 0) setForms([nameRef, emailFormRef, commentRef]);
    console.log('countRef', countRef.current);
    if (countRef.current == 1) {
      console.log('ready');

      var msg = '';

      forms.map((input, i) => {
        

        switch (input.current.message) {
          case undefined :
            return null;
          default :
            
            if (i == 0)
            { 
              console.log(i);
              console.log(msg);
              msg = `<ul> <li>${input.current.name} - ${input.current.message}</li>`;
            } else {
              console.log(i);
              console.log(msg);
              msg = msg + `<li> ${input.current.name} - ${input.current.message}</li>`;
            }

           

        
        }

        if (i == 4) {

          
          console.log('msg', msg);
          if (divResponse.current && divResponse.current.innerHTML) {
          divResponse.current.innerHTML = msg+'</ul>';
        }
        }

        
      });

      console.log('invalidFields', invalidFields);

    }


  }, [invalidFields, response, countRef]);


  return (
    <div>
      
      <header className="container">
     
    <figure className='brand animated fadeInLeft pull-left'>
      <a href="#home">
        <img src="/img/logo-light.webp" alt="logo" width="160" height="30" />
      </a>
    </figure>
    <div className="nav pull-right">
      <nav>
        <ul className="section-menu animated fadeInRight">
          <li data-menuanchor="home" className="active"><a href="#home"><span data-hover='Home'>Home</span></a></li>
          <li data-menuanchor="portfolio"><a href="#portfolio"><span data-hover='About'>About</span></a></li>
          <li data-menuanchor="contact"><a href="#contact"><span data-hover='Contact'>Contact</span></a></li>
        </ul>
      </nav>
      <div className="bt-nav">
        <div className="line line1"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
      </div>
    </div>
  </header>
  <main className='fullpage main'>
    <section className='corner-red home' data-anchor='home'>
      <div className="section-content-wrapper container">
        <div className="section-content">
          <div className="countdown-box" data-animation='fadeInDown' data-out-animation='fadeOutUp' data-out-animation-delay='200'>
            <h3 className="countdown days-left" data-date="2023, 09, 20 "></h3>
          </div>
          <h1 data-animation='fadeInDown' data-animation-delay='200' data-out-animation='fadeOutUp' data-out-animation-delay='400' className='section-main-title'>Coming Soon</h1>
          <p data-animation='fadeInDown' data-animation-delay='400' data-out-animation='fadeOutUp' data-out-animation-delay='600' className='lead'>
            The new Harmonya Lab is coming. We'll be here soon with our new awesome site,
            <br /> 
            subscribe to be notifed.
          </p>
          <p>{response}</p>
                <p>{msgResponse}</p>
          <div data-animation='fadeInDown' data-animation-delay='600' data-out-animation='fadeOutUp' data-out-animation-delay='800'>
            <form className="notify" method="post" action="#">
              <div className="btn">
                <label htmlFor="exampleInputEmail1">NOTIFY ME</label>
                
                <input className={`form-control ${isValid}`} id="email" name="subscribe" placeholder="Email" ref={emailRef} onChange={e => {setEmailVal(e.target.value); setisValid('')}} />
                <button className='btn-reset' id='btn-reset' type="submit" value='Submit' onClick={handleClick}>
                  <span className="gl-send top"></span>
                  <span className="gl-send bottom"></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="share">
        <div className="share-button">
          <img src="/img/share-light.svg" alt="sharing" width="16" height="18" />
        </div>
        <div className="social">
          <a href="#"><i className="fa fa-dribbble"></i></a>
          <a href="//www.facebook.com/ItemBridge"><i className="fa fa-facebook"></i></a>
          <a href="//twitter.com/Itembridge"><i className="fa fa-twitter"></i></a>
        </div>
      </div>
    </section>
    <section className='corner-red portfolio' data-anchor='portfolio'>
      <div className="section-content-wrapper container">
        <div className="section-content">
          <h2 data-animation='fadeInDown' data-out-animation='fadeOutUp' data-out-animation-delay='600' className='section-title'>About us</h2>
          <h4 data-animation='fadeInDown' data-animation-delay='200' data-out-animation='fadeOutUp' data-out-animation-delay='400' className='section-content-title'>
            A RELIABLE PARTNER FOR DIGITAL INNOVATION
          </h4>
          <div className="row">
            <p className='lead col-sm-offset-1 col-sm-10' data-animation='fadeInDown' data-animation-delay='400' data-out-animation='fadeOutUp' data-out-animation-delay='200'>
              Our company was founded with the goal of helping businesses navigate the constantly evolving world of digital technologies. We understand that it can be overwhelming to keep up with the latest trends and technologies, which is why we offer a range of services to help our clients succeed online.<br /><br />
			  Our team is made up of experienced professionals who have a passion for all things digital. We pride ourselves on staying up-to-date with the latest industry trends and techniques, and we are dedicated to helping our clients achieve their goals.
            </p>
          </div>
          { /* This block need for last item 
          <div className="preview-team-portfolio" >
            <a href="#" data-toggle="modal" data-target="#preview-team-portfolio-modal" data-animation='fadeInDown' data-animation-delay='600' data-out-animation='fadeOutUp'>

              </a>
  </div> */ }
        </div>
      </div>
    </section>
    <section className='corner-red contact' data-anchor='contact'>
      <div className="section-content-wrapper container">
        <div className="section-content">
          <h2 data-animation='fadeInDown' data-out-animation='fadeOutUp' data-out-animation-delay='600' className='section-title'>contact</h2>
          <div className="contact-info" data-animation='fadeInRight' data-animation-delay='200' data-out-animation='fadeOutRight' data-out-animation-delay='200'>
            <div className="row">
              <div className="col-sm-4">
                <address>
                  <i>here you find us</i>
                  <p data-toggle="modal" data-target="#map-address">71-75 Shelton Street Covent Garden London, WC2H 9JQ UNITED KINGDOM</p>             
                </address>
              </div>
              <div className="col-sm-4">
                <div className="phone">
                  <i></i>
                  <p></p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="email">
                  <i>write us a email</i>
                  <p>hello@harmonya.online</p>
                </div>
              </div>
            </div>
          </div>
          <div ref={divResponse}>{invalidFields}</div>
          <form action='/php/contact-form.php' className='contact-form' data-animation='fadeInRight' data-animation-delay='400' data-out-animation='fadeOutRight'>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="name" name='name' ref={nameRef} />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input className="email form-control" placeholder="email" name='email' ref={emailFormRef} />
                </div>
              </div>
            </div>
            <div className="form-group message">
              <textarea className="form-control" rows={1} placeholder="message" name='comment' ref={commentRef} ></textarea>
            </div>
            <button className='btn' type="submit" onClick={submitForm}>send</button>
            <span className="form-message" style={{display: "none"}}></span>
          </form>
        </div>
      </div>
    </section>
  </main>
 { /* This block need for last item */ }
  <div className="modal fade" id="preview-team-portfolio-modal" tabIndex={-1} role="dialog" aria-labelledby="preview-team-portfolio-modal">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-body">
          <button className='btn' type="button" data-dismiss="modal" aria-label="Close">
            <img src="/img/close-light.svg" alt="close" width="14" height="12" />
          </button>
          <div className="team-portfolio-list">
            <div className="item">
              <figure>
                <img className='replace-2x img-responsive'  src="/img/portfolio/portfolio-element-1.jpg" alt="pf1" />
                <div className="img-layer"></div>
                <figcaption>
                  <p className='social-team'>
                    <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                    <a href="#"><i className="fa fa-behance"></i></a>
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="item">
              <figure>
                <img className='replace-2x img-responsive'  src="/img/portfolio/portfolio-element-2.jpg" alt="pf2" />
                <div className="img-layer"></div>
                <figcaption>
                  <p className='social-team'>
                    <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                    <a href="#"><i className="fa fa-behance"></i></a>
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="item">
              <figure>
                <img className='replace-2x img-responsive'  src="/img/portfolio/portfolio-element-3.jpg" alt="pf3" />
                <div className="img-layer"></div>
                <figcaption>
                  <p className='social-team'>
                    <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                    <a href="#"><i className="fa fa-behance"></i></a>
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="item">
              <figure>
                <img className='replace-2x img-responsive'  src="/img/portfolio/portfolio-element-4.jpg" alt="pf4" />
                <div className="img-layer"></div>
                <figcaption>
                  <p className='social-team'>
                    <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                    <a href="#"><i className="fa fa-behance"></i></a>
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="item">
              <figure>
                <img className='replace-2x img-responsive'  src="/img/portfolio/portfolio-element-5.jpg" alt="pf5" />
                <div className="img-layer"></div>
                <figcaption>
                  <p className='social-team'>
                    <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                    <a href="#"><i className="fa fa-behance"></i></a>
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="item">
              <figure>
                <img className='replace-2x img-responsive'  src="/img/portfolio/portfolio-element-6.jpg" alt="pf6" />
                <div className="img-layer"></div>
                <figcaption>
                  <p className='social-team'>
                    <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                    <a href="#"><i className="fa fa-behance"></i></a>
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="item">
              <figure>
                <img className='replace-2x img-responsive'  src="/img/portfolio/portfolio-element-7.jpg" alt="pf7" />
                <div className="img-layer"></div>
                <figcaption>
                  <p className='social-team'>
                    <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                    <a href="#"><i className="fa fa-behance"></i></a>
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="item">
              <figure>
                <img className='replace-2x img-responsive'  src="/img/portfolio/portfolio-element-8.jpg" alt="pf8" />
                <div className="img-layer"></div>
                <figcaption>
                  <p className='social-team'>
                    <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                    <a href="#"><i className="fa fa-behance"></i></a>
                  </p>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="map-address" tabIndex={-1} role="dialog" aria-labelledby="map-address">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-body">
          <button className='btn' type="button" data-dismiss="modal" aria-label="Close">
            <img src="/img/close-light.svg" alt="close" width="14" height="12" />
          </button>
          <div id="map-canvas"></div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <div className="copyright">
      <p>© Harmonya Lab LTD, 2022</p>
    </div>
  </footer>

    </div>
  );
}

export default App;
