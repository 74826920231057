import axios from "axios";
import base64 from 'react-native-base64';

const srvConn = async (srvRoute: string, postData:any) => {
 
  //console.log("srvRoute", srvRoute);
  //console.log("postData", postData);
  const headers = {
    'Content-Type': 'application/json',
    "Authorization": "Basic " + base64.encode(`${process.env.REACT_APP_USER_API}:${process.env.REACT_APP_PWD_API}`),
  }

  
  try {

    const res :any = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}${srvRoute}`, postData, { headers: headers});

    if (res) {
    
    console.log("res", res);
    return {success: true}
      
    } else {
     
      var resp = (res.response == null) ? res : res.response?.data.error ;
      return {success: false, error: resp}
    
    }

  } catch (err :any) {
    console.log("err", err);
    return {success: false, error: err.response?.data.error}
  }
 

}

export default srvConn;